.columnHeader{
        min-width: 200px;
        max-height: 40px;
        font: normal normal medium 16px/20px Poppins;
        font-weight: bold;
        border-width: 0px;
        border-width: 1px;
        border-color: #EFEFEF;
        border-style: groove;
}

.content {
        border-width: 0;
        border-width: 1;
        font: normal normal medium 16px/20px Poppins;
        min-width: 200px;
        max-height: 40px;
        border-width: 0px;
        border-width: 1px;
        border-color: #EFEFEF;
        border-style: solid;
        background-color: #FFFFFF;
      }

/* .select {
        background-color: #E0FFF3;
        border-color: #57B793;
        height: 25px;
        width: 60px;
}

.style1{
        padding: 10px;
}

.style2{
        padding-left: 10px;
        padding-right: 10px;
} */