.dialog {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #333333;
    border-radius: 8px;
    opacity: 1;
    width: 91.66vw;
    height: 85.90vh; 
    margin-top: 88px;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 188px;
}

.headerContainer {
    padding: 24px 24px;
}

.header {
    text-align: left;
    font: normal normal 500 24px/32px poppins;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
}

.formContainer{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 10px;
    opacity: 1;
    padding: 24px 24px;
    overflow-y: auto;
    height: 62.73vh;
    width: 89.16vw;
    margin-left:24px;
    margin-right:24px;
}

.buttonContainer{
    padding: 24px 24px;
}

.buttonOutlinedText {
    text-align: center;
    font: normal normal 500 22px/30px poppins;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.buttonBack {
    padding-right: 24px
}

.buttonText{
    text-align: center;
    font: normal normal 500 22px/30px poppins;
    letter-spacing: 0px;
    color: #5BB996;
    opacity: 1;
}

.buttonOutlined { 
    width: 228px;
    height: 56px;
    border-radius: 32px;
    opacity: 1;
}

/* @media screen and (width <= 1500px) {
    .buttonOutlined { 
        width: 256px;
        height: 64px;
        border-radius: 32px;
        opacity: 1;
    }
} */