/* .App {
  font-family: sans-serif;
  text-align: center;
} */
html,

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'sans-serif';
  max-width: 100vw;
  word-spacing: 0;
  background-color: #EFEFEF;
}

.scrollbar::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 12px;
  height: 6px;
  border-radius: 30px;
}

.scrollbar::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: rgba(230, 230, 230, 0.8);
}

.scrollbar::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: #5CB593 0% 0% no-repeat padding-box;
  border: 1px solid #5CB593;
  border-radius: 8px;
  opacity: 1;
}
