.MainContainer{
   
    background: #000000 0% 0% no-repeat padding-box;
}

.ContainerLeft{
    padding: 40px 16px 16px 16px;
    background: #0D0D0D 0% 0% no-repeat padding-box;
    opacity: 1;
    height: auto;
}

.Logo{
    width: 160px
}

.TextField{
    font: normal normal 600 16px/24px Nunito Sans;
    background:#333333 0% 0% no-repeat padding-box;
    border: 1px solid #777777
}

.Form{
    padding-top: 190px;
}

.HelperText{
    font: normal normal 800 16px/24px ;
    color: red
}

.ButtonPadding{
    padding-top: 30px 
}

.ButtonStyle{
    height: 50px;
    background-Color: #57B793
}

.Footer{
    font: normal normal normal 14px/24px Nunito Sans;
    color: #F2F4F4;
    padding-top:240px
}
.TextColor{
    color:white
}

.ContainerRight{
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 1
}
.IconColor{
    fill: #ffffff
}
.FavIcon{
    fill:#57B793
}