.mainContainer {
  padding-top: 10px;
}

.planAndBillingText {
  border: 1px solid rgba(222,222,223, .5);
  padding: 12px 0px 12px 10px;
}

.columnHeading {
  padding: 20px 0px 20px 0px;
}

.boxBorder {
  border: 1px solid rgba(222,222,223, .5);
}

.boxBorderWithHeight {
  border: 1px solid rgba(222,222,223, .5);
  height: 100px;
}

.switchPadding {
  padding: 10px 0px 10px 0px;
}

.textBox {
  padding-left: 10px;
  height: 100%;
}