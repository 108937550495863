.rolesHeader {
   font: normal normal 500 24px/32px poppins;
   letter-spacing: 0px;
    color: #212121;
    opacity: 1;
    padding-bottom: 10px;
}

.categoryList {
  border-radius: 5px;
  background-color: #EFEFEF;
  padding: 10px 10px 0px 10px;
  height: 400px;
  overflow: auto;
}

.categoryListItem {
  border-bottom: 1px solid grey;
  cursor: pointer;
  padding: 10px;                    
}

.categoryListItemText {
  background-color: #EFEFEF;
}

.categoryListItemTextColor {
  color: #5CB593;
}

.categoryDetailBox {
  padding-left: 20px;
}

.categoryDetailBoxBorder {
  border: 1px solid rgba(222,222,223, .5);
  border-radius: 5px;
  padding: 20px;
}

.tableTitle {
   font: normal normal 500 18px/26px poppins;
   letter-spacing: 0px;
   color: #212121;
   opacity: 1;
   padding-bottom: 10px;
}

.tableContainer {
   width: 100%;
   overflow: hidden;
}

.table {
  max-height: 265px ;
}

.noTableMessage {
    padding-top: 175px;
    height: 300px;
}