.billingHeader {
   font: normal normal 500 24px/32px poppins;
   letter-spacing: 0px;
    color: #212121;
    opacity: 1;
    padding-bottom: 10px;
}
.mainContainer {
  padding-bottom: 10px;
}

.planListContainer {
  background-color: #EFEFEF;
  border-radius: 5px;
  height: 390px;
  overflow: auto;
  padding: 10px;
}
 
.planListItem {
  width: 100%;
  max-width: 360;
  border-bottom: 1px solid grey;           
}

.collapseListItem {
  padding-left: 16px;
}

.nextIcon {
  padding: 4px 0px 0px 10px;
}

.subPlanSelectedColor {
  color: #5CB593;
}

.rightMainContainer {
   padding-left: 10px;

}

.containerPadding {
   padding-bottom: 10px;
} 

.rightMainSubContainer {
    padding: 20px;
    border: 1px solid rgba(222,222,223, .5);
    border-radius: 5px;
    height: 390px;
    overflow: auto;
}

.noFormMessage {
    padding-top: 175px;
    height: 300px;
}

.dateSelectContainer {
  padding: 15px 0px 20px 11px;
}

