.header {
    text-align: left;
    font: normal normal 600 24px/32px poppins;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
}

.modalStyle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainContainer {
    background-color: white;
    padding: 20px 15px 20px 20px;
}

.allButtonsMainItem {
    padding: 20px 0px 0px 0px
}

.buttonText {
    font: 'normal normal 600 16px/22px Poppins';
}

.buttonItem {
    padding-left: 30px;
}

.button {
    width: 228px;
    height: 56px;
    border-radius: 32px;
    opacity: 1;
}