.header {
    text-align: left;
    font: normal normal 600 24px/32px poppins;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
}

.button {
    width: 228px;
    height: 56px;
    border-radius: 32px;
    opacity: 1;
}

.tableContainer {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    width: 100%;
}

.searchBar {
    width: 22.22vw;
    padding: 16px 16px
}
